@media all and (min-width: 480px) {
    .Login-forms {
      padding: 60px 0;
    }
  
    .Login-forms {
      margin: 0 auto;
      max-width: 320px;
    }
  }

  .form-group--password {
    margin-bottom: 0 !important;
  }
  
  .LoaderButton--login {
    margin-top: 1rem;
  }