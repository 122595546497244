.row--footer{
    font-size: 12px;
    margin: 0.5vh 0;
    background-color: white;
    border-top: 1px solid;
    border-top-color: rgba(0, 0, 0, 0.1);
}
.row--footer, .row--footer a{
    color: rgba(100, 100, 100, 1);
}
.row--footer p{
    margin: 0;
  }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .row--footer {
        font-size: 14px
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .row--footer {
        font-size: 16px;
        margin: 1vh 0;
    }
}