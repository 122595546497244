
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px!important;
}
h2 {
  font-size: 20px!important;
}
h4 {
  font-size: 18px!important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  body {
    font-size: 16px!important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    body {
      font-size: 16px!important;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
      body {
        font-size: 18px!important;
      }
      h4 {
        font-size: 20px!important;
      }
      h2 {
        font-size: 22px!important;
      }
}







.App {
  text-align: center;
}
html {
  position: relative;
  height: 100%;
}
body {
  height: 100vh;
}
.container-fluid {
  height: 100vh;
}

.loader-wrapper{
  text-align: center;
  padding-top: 10%;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
  }
.lander {
    padding: 80px 0;
    text-align: center;
  }
  
.lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.lander p {
  color: #999;
}

 /*  .Home h2 {
    padding-top: 20px;
  }
  .Home h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Home {
    color: #666;
  } */
.LoaderButton .spinning-icon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
  @keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
@media all and (min-width: 480px) {
    .Login-forms {
      padding: 60px 0;
    }
  
    .Login-forms {
      margin: 0 auto;
      max-width: 320px;
    }
  }

  .form-group--password {
    margin-bottom: 0 !important;
  }
  
  .LoaderButton--login {
    margin-top: 1rem;
  }

.form--login a{
  color: #212529;
  text-decoration: underline;
  font-size: 16px;
}
@media all and (min-width: 480px) {
    .ResetPassword {
      padding: 60px 0;
    }
  
    .ResetPassword form {
      margin: 0 auto;
      max-width: 320px;
    }
  
    .ResetPassword .success {
      max-width: 400px;
    }
  }
  
  .ResetPassword .success {
    margin: 0 auto;
    text-align: center;
  }

.row--redirect {
  text-align: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.col--redirect {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}


.row--navbar {    
    border-bottom: 1px solid;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-right: -15px;
    margin-left: -15px;
}
.row--content {
    background: rgba(0, 0, 0, 0.01);
}

.row--footer{
    font-size: 12px;
    margin: 0.5vh 0;
    background-color: white;
    border-top: 1px solid;
    border-top-color: rgba(0, 0, 0, 0.1);
}
.row--footer, .row--footer a{
    color: rgba(100, 100, 100, 1);
}
.row--footer p{
    margin: 0;
  }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .row--footer {
        font-size: 14px
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .row--footer {
        font-size: 16px;
        margin: 1vh 0;
    }
}
