.row--redirect {
  text-align: center;
  flex-direction: column;
}
.col--redirect {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
