.App {
  text-align: center;
}
html {
  position: relative;
  height: 100%;
}
body {
  height: 100vh;
}
.container-fluid {
  height: 100vh;
}

.loader-wrapper{
  text-align: center;
  padding-top: 10%;
}