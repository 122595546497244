.lander {
    padding: 80px 0;
    text-align: center;
  }
  
.lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.lander p {
  color: #999;
}
