
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px!important;
}
h2 {
  font-size: 20px!important;
}
h4 {
  font-size: 18px!important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  body {
    font-size: 16px!important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    body {
      font-size: 16px!important;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
      body {
        font-size: 18px!important;
      }
      h4 {
        font-size: 20px!important;
      }
      h2 {
        font-size: 22px!important;
      }
}






